import * as React from "react"
import { graphql } from "gatsby"
import Home from "./Home";
import "../scss/main.scss"
import get from "just-safe-get"

export default function IndexPage({ data }) {
  const siteData = get(data, "siteData.data", null)
  return (
    <>
      <Home siteData={siteData} />
    </>
  )
}
export const IndexQuery = graphql`
  query {
    siteData: prismicSite {
      data {
        tagline {
          richText
        }
      }
    }
    shopifyCollection(title: { eq: "A Very Good Plan" }) {
      products {
        ...ProductCard
      }
    }
  }
`
